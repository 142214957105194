<template>
  <div class="industry-list-wrapper">
    <div
      v-for="(item, idx) in listData"
      :key="idx"
      class="industry-item"
      @click="onLegendClick(item)"
    >
      <div class="left-block">
        <span
          :class="{ 'color-muted': item.isSelected }"
          class="number-text"
        >
          {{ idx + 1 }}
        </span>
      </div>
      <div class="name-block">
        <div class="industry-title">
          <div class="industry-name">
            <span
              :class="{ 'color-muted': item.isSelected }"
              class="name"
            >
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="center-block">
        <div class="center-value">
          <div
            :class="{ 'color-muted': item.isSelected }"
            class="value"
          >
            {{ formatValue(item.value) }}
          </div>
          <div
            :class="{ 'color-muted': item.isSelected }"
            class="value"
          >
            {{ '(' + item.valueAsPercent + '%)' }}
          </div>
        </div>
      </div>
      <div class="right-block">
        <div
          class="prog-bar"
          :style="{
            flexBasis: `${item.valueAsPercent}%`,
          }"
        >
          <span
            class="prog-segment"
            :class="{ 'bar-muted': item.isSelected }"
            :title="`${item.valueAsPercent}% (${item.value})`"
            :style="{
              backgroundColor: `${item.itemStyle.color}`,
            }"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '@/services/helper';
export default {
  name: 'InterestLists',
  props: {
    listData: Array,
  },
  emits: ['legendClick'],
  setup(props, { emit }) {
    const formatValue = (value) => {
      return helper.numeral(value, '0,0');
    };
    const onLegendClick = (item) => {
      if (item.isSelected) {
        item.isSelected = false;
      } else {
        item.isSelected = true;
      }
      emit('legendClick', item);
    };
    return {
      onLegendClick,
      formatValue,
    };
  },
};
</script>

<style lang="scss">
@import '../../config/theme/colors.json';

.industry-list-wrapper {
  max-width: 490px;
  padding: 0;
  display: flex;
  flex-direction: column;

  .color-muted {
    color: #ccc !important;
  }

  .bar-muted {
    opacity: 0.5;
  }

  @media only screen and (max-width: 766px) {
    padding: 0;
    min-width: 365px;
    min-height: 180px;
    align-self: center;
  }

  .industry-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      padding-bottom: 8px;
      border-bottom: 1px solid #f1f2f6;
      margin-bottom: 8px;
    }

    .left-block {
      flex: 1;
      display: flex;
      min-width: 10px;
      transform: translateX(0px);
      overflow: hidden;
      white-space: nowrap;
      align-items: center;

      .number-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #272b41;
      }
    }

    .name-block {
      flex: 10;
      min-width: 100px;
      transform: translateX(0px);
      overflow: hidden;
      white-space: nowrap;

      .industry-title {
        .industry-name {
          text-transform: capitalize;
          color: #5a5f7d;
        }
      }
    }

    .center-block {
      flex: 1;
      display: flex;
      min-width: 90px;
      transform: translateX(20px);

      .center-value {
        color: #5a5f7d;
      }
    }

    .right-block {
      flex: 4;
      display: flex;
      transform: translateX(10px);

      .prog-bar {
        height: 10px;

        .prog-segment {
          text-align: center;
          color: #fff;
          height: 10px;
          line-height: 10px;
          border-radius: 8px;
          display: block;
        }
      }
    }

    .color-muted {
      color: #ccc;
    }
  }
}
</style>
