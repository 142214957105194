<template>
  <div class="mobile-table">
    <table class="table">
      <tbody>
        <template
          v-for="(item, index) in lists"
          :key="item.id"
        >
          <tr
            :class="{ expand: expandRowId === index }"
            :rowspan="expandRowId === index ? 2 : 1"
            class="item-row"
          >
            <td
              v-if="index <= 2"
              class="top-index text-center"
              @click="showMessageModal(item.id, item.info.user_name)"
            >
              <img
                v-if="index === 0"
                :src="require('@/assets/images/icon/01-medal-gold.svg')"
              />
              <img
                v-if="index === 1"
                :src="require('@/assets/images/icon/02-medal-silver.svg')"
              />
              <img
                v-if="index === 2"
                :src="require('@/assets/images/icon/03-medal-bronze.svg')"
              />
            </td>
            <td
              v-else
              class="text-center index-td"
              @click="showMessageModal(item.id, item.info.user_name)"
            >
              {{ index + 1 }}
            </td>
            <td
              class="top info-td"
              @click="showMessageModal(item.id, item.info.user_name)"
            >
              <div class="d-flex">
                <div class="user-photo-wrap">
                  <img
                    class="user-photo"
                    :src="item.newProfileImage"
                    @error="onImageError($event, item)"
                  />
                  <SourceTag
                    :source="item.source"
                    :showLabel="false"
                  ></SourceTag>
                  <!-- <span class="source-tag" :class="item.source">
                    <span class="logo">
                      <span v-if="item.source === 'facebook'" class="inner facebook">
                        <font-awesome-icon :icon="faFacebookF" />
                      </span>
                      <span v-else-if="item.source === 'twitter' || item.source === 'twitterv2'" class="inner twitter">
                        <font-awesome-icon :icon="faTwitter" />
                      </span>
                      <span v-else-if="item.source === 'instagram'" class="inner instagram">
                        <font-awesome-icon :icon="faInstagram" />
                      </span>
                      <span v-else-if="item.source === 'youtube'" class="inner youtube">
                        <font-awesome-icon :icon="faYoutube" />
                      </span>
                      <span v-else-if="item.source === 'blockdit'" class="blockdit">
                        <img :src="require('@/assets/images/source-icon/transparent/blockdit.svg')" />
                      </span>
                      <FeatherIcon
                        v-else-if="item.source === 'pantip'"
                        class="pantip-icon"
                        type="message-square"
                        :size="9"
                      />
                      <FeatherIcon v-else class="website-icon" type="globe" :size="8" />
                    </span>
                  </span> -->
                </div>
                <div class="user-info">
                  <div class="username">
                    {{ item.info.user_name }}
                  </div>
                  <div
                    v-if="item.types.length > 3"
                    id="InfluencerType"
                    class="type-row"
                  >
                    <span
                      v-for="(item, index) in item.showTag"
                      :key="index"
                      class="type"
                    >
                      <a-tooltip
                        v-if="checkStr(item)"
                        placement="bottom"
                        class="tooltip-mobile"
                      >
                        <template #title>
                          <div class="type-row">
                            <span class="type">
                              {{ item }}
                            </span>
                          </div>
                        </template>
                        {{ item }}
                      </a-tooltip>
                      <span v-else>
                        {{ item }}
                      </span>
                    </span>
                    <a-tooltip
                      placement="bottom"
                      class="types"
                    >
                      <template #title>
                        <div class="type-row">
                          <div
                            v-for="(item, index) in item.tooltip"
                            :key="index"
                            class="type"
                          >
                            {{ item }}
                          </div>
                        </div>
                      </template>
                      <div class="type">{{ item.moreItem }}</div>
                    </a-tooltip>
                  </div>
                  <div
                    v-else-if="
                      item.types.length <= 3 && item.types.length !== 0
                    "
                    id="InfluencerTypeAll"
                    class="type-row"
                  >
                    <span
                      v-for="(item, index) in item.types"
                      :key="index"
                      class="type"
                    >
                      <a-tooltip
                        v-if="checkStr(item)"
                        placement="bottom"
                        class="tooltip-mobile"
                      >
                        <template #title>
                          <div class="type-row">
                            <span class="type">
                              {{ item }}
                            </span>
                          </div>
                        </template>
                        {{ item }}
                      </a-tooltip>
                      <span v-else>
                        {{ item }}
                      </span>
                    </span>
                  </div>
                  <div
                    v-else
                    id="InfluencerTypeAll"
                    class="type-row-empty"
                  ></div>
                  <!-- <div class="influencer-row">
                    <div class="influencer first">
                      <FeatherIcon class="influencer-icon" type="users" :size="12" />
                      7.3M
                    </div>
                    <div class="influencer">
                      <FeatherIcon class="influencer-icon" type="pie-chart" :size="12" />
                      2.1%
                    </div>
                  </div> -->
                </div>
              </div>
            </td>
            <td
              class="text-center engagement-td top"
              @click="showMessageModal(item.id, item.info.user_name)"
            >
              <div class="unit-text">
                <span v-if="sortBy === 'engagement'">Engagement</span>
                <span v-else-if="sortBy === 'count'">Mentioned</span>
                <span v-else-if="sortBy === 'like_reaction_count'"
                  >Like/Reaction</span
                >
                <span v-else-if="sortBy === 'comment_count'">Comment</span>
                <span v-else-if="sortBy === 'share_count'">Share</span>
                <span v-else-if="sortBy === 'view_count'">View</span>
              </div>
              <div class="value-text">
                <span
                  v-if="sortBy === 'engagement'"
                  class="blue-text"
                >
                  {{ item.totalEngage }}
                </span>
                <span
                  v-else-if="sortBy === 'count'"
                  class="blue-text"
                >
                  {{ item.totalMention }}
                </span>
                <span
                  v-else-if="sortBy === 'like_reaction_count'"
                  class="blue-text"
                >
                  {{ item.engagement.reaction || 0 }}
                </span>
                <span
                  v-else-if="sortBy === 'comment_count'"
                  class="blue-text"
                >
                  {{ item.engagement.comment || 0 }}
                </span>
                <span
                  v-else-if="sortBy === 'share_count'"
                  class="blue-text"
                >
                  {{ item.engagement.share || 0 }}
                </span>
                <span
                  v-else-if="sortBy === 'view_count'"
                  class="blue-text"
                >
                  {{ item.engagement.view || 0 }}
                </span>
              </div>
            </td>
            <td class="text-center top menu-td">
              <div class="menu-item">
                <a-dropdown
                  :placement="'bottomRight'"
                  trigger="click"
                >
                  <div
                    class="user-menu-button"
                    @click.stop=""
                  >
                    <FeatherIcon
                      type="more-horizontal"
                      size="18"
                    />
                  </div>
                  <template #overlay>
                    <a-menu class="user-influencer-menu">
                      <a-menu-item
                        class="user-menu-item-wrapper"
                        @click="openUserLink(item)"
                      >
                        <a class="user-menu-item">
                          <FeatherIcon type="external-link" />
                          Open Link
                        </a>
                      </a-menu-item>
                      <a-menu-item
                        class="user-menu-item-wrapper"
                        @click="copyUserLink(item)"
                      >
                        <a class="user-menu-item">
                          <FeatherIcon type="copy" />
                          Copy Link
                        </a>
                      </a-menu-item>
                      <a-menu-item
                        class="user-menu-item-wrapper"
                        @click="showMessageModal(item.id, item.info.user_name)"
                      >
                        <a class="user-menu-item">
                          <FeatherIcon type="eye" />
                          View Message
                        </a>
                      </a-menu-item>
                      <a-menu-item
                        class="user-menu-item-wrapper"
                        @click="showNetworkGraph(item)"
                      >
                        <a class="user-menu-item">
                          <FeatherIcon type="share-2" />
                          Network Graph
                        </a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
              <div
                class="expand-item"
                @click.stop="toggleExpandRow(index)"
              >
                <FeatherIcon
                  v-if="expandRowId === index"
                  type="chevron-up"
                />
                <FeatherIcon
                  v-else
                  type="chevron-down"
                ></FeatherIcon>
              </div>
            </td>
          </tr>
          <tr
            v-show="expandRowId === index"
            class="expand-row"
          >
            <td colspan="4">
              <div class="expand-total">
                <div class="total-list">
                  <div class="total-block">
                    <div class="value-row">
                      {{ item.totalEngage }}
                    </div>
                    <div class="unit-row">Engagement</div>
                  </div>
                  <div class="total-block">
                    <div class="value-row">
                      {{ item.totalMention }}
                    </div>
                    <div class="unit-row">Mentioned</div>
                  </div>
                  <div class="total-block">
                    <div class="value-row">
                      {{ item.engagement.view || 0 }}
                    </div>
                    <div class="unit-row">Views</div>
                  </div>
                </div>
                <div class="action-list small">
                  <div class="title">Engagement</div>
                  <div
                    v-if="typeof item.engagement.reaction === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        :type="'thumbs-up'"
                        size="12"
                      />
                      Reactions
                    </div>
                    <div class="action-value">
                      {{ item.engagement.reaction || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.like === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        v-if="
                          item.source === 'twitter' ||
                          item.source === 'twitterv2'
                        "
                        :type="'heart'"
                        size="12"
                      />
                      <FeatherIcon
                        v-else
                        :type="'thumbs-up'"
                        size="12"
                      />
                      Like
                    </div>
                    <div class="action-value">
                      {{ item.engagement.like || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.comment === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        :type="'message-square'"
                        size="12"
                      />
                      <span
                        v-if="
                          item.source === 'twitter' ||
                          item.source === 'twitterv2'
                        "
                        >Reply</span
                      >
                      <span v-else>Comment</span>
                    </div>
                    <div class="action-value">
                      {{ item.engagement.comment || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.share === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        :type="'share-2'"
                        size="12"
                      />
                      Share
                    </div>
                    <div class="action-value">
                      {{ item.engagement.share || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.retweet === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        :type="'repeat'"
                        size="12"
                      />
                      Retweet
                    </div>
                    <div class="action-value">
                      {{ item.engagement.retweet || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.engagement.quote === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        :type="'edit-3'"
                        size="12"
                      />
                      Quote
                    </div>
                    <div class="action-value">
                      {{ item.engagement.quote || 0 }}
                    </div>
                  </div>
                </div>
                <div
                  class="action-list small"
                  :class="{
                    'no-bordersmall':
                      item.source === 'twitter' && item.source === 'twitterv2',
                  }"
                >
                  <div class="title">Mentioned</div>
                  <div
                    v-if="typeof item.mention.post === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        :type="'file-text'"
                        size="12"
                      />
                      Post
                    </div>
                    <div class="action-value">
                      {{ item.mention.post || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.mention.tweet === 'string'"
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        :type="'twitter'"
                        size="12"
                      />
                      Tweet
                    </div>
                    <div class="action-value">
                      {{ item.mention.tweet || 0 }}
                    </div>
                  </div>
                  <div
                    v-if="typeof item.mention.comment === 'string'"
                    class="action-row"
                  >
                    <div
                      v-if="
                        item.source === 'twitter' || item.source === 'twitterv2'
                      "
                      class="action-title"
                    >
                      <FeatherIcon
                        :type="'repeat'"
                        size="12"
                      />
                      <span>Retweet</span>
                    </div>
                    <div
                      v-else
                      class="action-title"
                    >
                      <FeatherIcon
                        :type="'message-square'"
                        size="12"
                      />
                      <span>Comment</span>
                    </div>
                    <div class="action-value">
                      {{ item.mention.comment || 0 }}
                    </div>
                  </div>
                </div>
                <div class="action-list no-bordersmall">
                  <div class="title">Views</div>
                  <!-- <div
                    v-if="typeof item.view.video_view === 'string'"
                    class="action-row"
                  > -->
                  <div
                    v-if="
                      item.source === 'facebook' ||
                      item.source === 'instagram' ||
                      item.source === 'youtube' ||
                      item.source === 'twitter' ||
                      item.source === 'twitterv2'
                    "
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        :type="'eye'"
                        size="12"
                      />
                      Video View
                    </div>
                    <div class="action-value">
                      <span>{{ item.engagement.view || 0 }}</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.source !== 'youtube' &&
                      item.source !== 'facebook' &&
                      item.source !== 'instagram' &&
                      item.source !== 'twitter' &&
                      item.source !== 'twitterv2'
                    "
                    class="action-row"
                  >
                    <div class="action-title">
                      <FeatherIcon
                        :type="'bar-chart'"
                        size="12"
                      />
                      Page View
                    </div>
                    <div class="action-value">
                      <span>{{ item.engagement.view || 0 }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <div class="item-table">
    <table
      class="table"
      @scroll="onScroll"
    >
      <tbody>
        <tr
          v-for="(item, index) in lists"
          :key="item.id"
          class="item-row"
          @click="showMessageModal(item.id, item.info.user_name)"
        >
          <td
            v-if="index <= 2"
            class="top-index text-center middle"
          >
            <img
              v-if="index === 0"
              :src="require('@/assets/images/icon/01-medal-gold.svg')"
            />
            <img
              v-if="index === 1"
              :src="require('@/assets/images/icon/02-medal-silver.svg')"
            />
            <img
              v-if="index === 2"
              :src="require('@/assets/images/icon/03-medal-bronze.svg')"
            />
          </td>
          <td
            v-else
            class="text-center middle"
          >
            {{ index + 1 }}
          </td>
          <td class="middle">
            <div class="d-flex">
              <div class="user-photo-wrap">
                <img
                  class="user-photo"
                  :src="item.newProfileImage"
                  @error="onImageError($event, item)"
                />
                <SourceTag
                  :source="item.source"
                  :showLabel="false"
                ></SourceTag>
                <!-- <span class="source-tag" :class="item.source">
                  <span class="logo">
                    <span v-if="item.source === 'facebook'" class="inner facebook">
                      <font-awesome-icon :icon="faFacebookF" />
                    </span>
                    <span v-else-if="item.source === 'twitter' || item.source === 'twitterv2'" class="inner twitter">
                      <font-awesome-icon :icon="faTwitter" />
                    </span>
                    <span v-else-if="item.source === 'instagram'" class="inner instagram">
                      <font-awesome-icon :icon="faInstagram" />
                    </span>
                    <span v-else-if="item.source === 'youtube'" class="inner youtube">
                      <font-awesome-icon :icon="faYoutube" />
                    </span>
                    <span v-else-if="item.source === 'blockdit'" class="blockdit">
                      <img :src="require('@/assets/images/source-icon/transparent/blockdit.svg')" />
                    </span>
                    <FeatherIcon
                      v-else-if="item.source === 'pantip'"
                      class="pantip-icon"
                      type="message-square"
                      :size="9"
                    />
                    <FeatherIcon v-else class="website-icon" type="globe" :size="8" />
                  </span>
                </span> -->
              </div>
              <div class="user-info">
                <div class="username">
                  {{ item.info.user_name }}
                </div>
                <div class="account">
                  {{ '@' + item.info.user_short_id }}
                </div>
                <div
                  v-if="item.types.length > 3"
                  id="InfluencerType"
                  class="type-row"
                >
                  <span
                    v-for="(item, index) in item.showTag"
                    :key="index"
                    class="type"
                  >
                    {{ item }}
                  </span>
                  <a-tooltip placement="bottom">
                    <template #title>
                      <div class="type-row">
                        <div
                          v-for="(item, index) in item.tooltip"
                          :key="index"
                          class="type"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </template>
                    <div class="type">{{ item.moreItem }}</div>
                  </a-tooltip>
                </div>
                <div
                  v-else-if="item.types.length <= 3 && item.types.length !== 0"
                  id="InfluencerTypeAll"
                  class="type-row"
                >
                  <div
                    v-for="(item, index) in item.types"
                    :key="index"
                    class="type"
                  >
                    {{ item }}
                  </div>
                </div>
                <div
                  v-else
                  id="InfluencerTypeAll"
                  class="type-row-empty"
                ></div>
                <!-- <div class="engagement-row">
                  <div class="engagement first">
                    <FeatherIcon class="engagement-icon" type="users" :size="12" />
                    7.3M
                  </div>
                  <div class="engagement">
                    <FeatherIcon class="engagement-icon" type="pie-chart" :size="12" />
                    2.1%
                  </div>
                </div> -->
              </div>
            </div>
          </td>
          <td>
            <div class="unit-text">
              <span>Engagement</span>
            </div>
            <div
              class="value-text"
              :class="{ 'blue-text': sortBy === 'engagement' }"
            >
              <span>
                {{ item.totalEngage }}
              </span>
            </div>
            <div class="engagement-row">
              <div
                v-if="
                  item.source !== 'twitter' &&
                  item.source !== 'twitterv2' &&
                  item.source !== 'website'
                "
                class="engagement first"
              >
                <FeatherIcon
                  class="engagement-icon"
                  type="thumbs-up"
                  :size="12"
                />
                <span v-if="item.source === 'facebook'">{{
                  item.engagement.reaction || 0
                }}</span>
                <span v-else>{{ item.engagement.like || 0 }}</span>
              </div>
              <div
                v-if="item.source === 'twitter' || item.source === 'twitterv2'"
                class="engagement first"
              >
                <FeatherIcon
                  class="engagement-icon"
                  type="heart"
                  :size="12"
                />
                <span>{{ item.engagement.like || 0 }}</span>
              </div>
              <div
                v-if="item.source === 'youtube'"
                class="engagement"
              >
                <FeatherIcon
                  class="engagement-icon"
                  type="thumbs-down"
                  :size="12"
                />
                <span>{{ item.engagement.dislike || 0 }}</span>
              </div>
              <div class="engagement first">
                <FeatherIcon
                  class="engagement-icon"
                  type="message-square"
                  :size="12"
                />
                <span>{{ item.engagement.comment || 0 }}</span>
              </div>
              <div
                v-if="item.source !== 'twitter' && item.source !== 'twitterv2'"
                class="engagement"
              >
                <FeatherIcon
                  class="engagement-icon"
                  type="share-2"
                  :size="12"
                />
                <span>{{ item.engagement.share || 0 }}</span>
              </div>
              <div
                v-if="item.source === 'twitter' || item.source === 'twitterv2'"
                class="engagement first"
              >
                <FeatherIcon
                  class="engagement-icon"
                  type="repeat"
                  :size="12"
                />
                <span>{{ item.engagement.retweet || 0 }}</span>
              </div>
              <div
                v-if="item.source === 'twitter' || item.source === 'twitterv2'"
                class="engagement"
              >
                <FeatherIcon
                  class="engagement-icon"
                  type="edit-3"
                  :size="12"
                />
                <span>{{ item.engagement.quote || 0 }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="unit-text">
              <span>Mentions</span>
            </div>
            <div
              class="value-text"
              :class="{ 'blue-text': sortBy === 'count' }"
            >
              <span>
                {{ item.totalMention }}
              </span>
            </div>
            <div class="engagement-row">
              <div class="engagement first">
                <FeatherIcon
                  v-if="
                    item.source === 'twitter' || item.source === 'twitterv2'
                  "
                  class="engagement-icon"
                  type="twitter"
                  :size="12"
                />
                <FeatherIcon
                  v-else
                  class="engagement-icon"
                  type="file-text"
                  :size="12"
                />
                <span
                  v-if="
                    item.source === 'twitter' || item.source === 'twitterv2'
                  "
                  >{{ item.mention.tweet || 0 }}</span
                >
                <span v-else>{{ item.mention.post || 0 }}</span>
              </div>
              <div class="engagement">
                <FeatherIcon
                  v-if="
                    item.source === 'twitter' || item.source === 'twitterv2'
                  "
                  class="engagement-icon"
                  type="repeat"
                  :size="12"
                />
                <FeatherIcon
                  v-else
                  class="engagement-icon"
                  type="message-square"
                  :size="12"
                />
                <span>{{ item.mention.comment || 0 }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="unit-text">
              <span>Sentiment</span>
            </div>
            <div class="sentiment-list">
              <div class="sentiment-block">
                <div class="sentiment-circle positive">
                  <FeatherIcon
                    :type="'smile'"
                    :size="10"
                  />
                </div>
                <div
                  class="value"
                  :class="{ zero: item.sentiment.positive === 0 }"
                >
                  {{ item.sentiment.positive || 0 }}
                </div>
              </div>
              <div class="sentiment-block">
                <div class="sentiment-circle neutral">
                  <FeatherIcon
                    :type="'meh'"
                    :size="10"
                  />
                </div>
                <div
                  class="value"
                  :class="{ zero: item.sentiment.neutral === 0 }"
                >
                  {{ item.sentiment.neutral || 0 }}
                </div>
              </div>
              <div class="sentiment-block">
                <div class="sentiment-circle negative">
                  <FeatherIcon
                    :type="'frown'"
                    :size="10"
                  />
                </div>
                <div
                  class="value"
                  :class="{ zero: item.sentiment.negative === 0 }"
                >
                  {{ item.sentiment.negative || 0 }}
                </div>
              </div>
            </div>
          </td>
          <td class="border-none">
            <div class="unit-text">
              <span>Views</span>
            </div>
            <div class="sentiment-list">
              <div
                v-if="
                  (item.source === 'facebook' ||
                    item.source === 'instagram' ||
                    item.source === 'youtube') &&
                  item.source !== 'twitter' &&
                  item.source !== 'twitterv2'
                "
                class="view"
              >
                <FeatherIcon
                  class="view-icon"
                  type="eye"
                  :size="12"
                />
                <span>{{ item.engagement.view || 0 }}</span>
              </div>
              <div
                v-if="
                  item.source !== 'youtube' &&
                  item.source !== 'facebook' &&
                  item.source !== 'instagram' &&
                  item.source !== 'twitter' &&
                  item.source !== 'twitterv2'
                "
                class="view"
              >
                <FeatherIcon
                  class="view-icon"
                  type="bar-chart"
                  :size="12"
                />
                <span>{{ item.engagement.view || 0 }}</span>
              </div>
            </div>
          </td>
          <td class="border-none">
            <a-dropdown
              :placement="'bottomRight'"
              trigger="click"
            >
              <div
                class="user-menu-button"
                @click.stop=""
              >
                <FeatherIcon
                  type="more-horizontal"
                  size="18"
                />
              </div>
              <template #overlay>
                <a-menu class="user-influencer-menu">
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="openUserLink(item)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="external-link" />
                      Open Link
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="copyUserLink(item)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="copy" />
                      Copy Link
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="showMessageModal(item.id, item.info.user_name)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="eye" />
                      View Message
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="showNetworkGraph(item)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="share-2" />
                      Network Graph
                    </a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
    <a-modal
      v-model:visible="isNetworkModalOpen"
      :title="networkData.title"
      :footer="null"
      width="838px"
      :z-index="1000"
    >
      <div
        v-if="networkData.loading"
        class="loading-wrap"
      >
        <a-spin />
      </div>
      <div
        v-show="!networkData.loading"
        id="influencer-graph"
        ref="infGraph"
      ></div>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, provide, toRefs, computed } from 'vue';
import {
  faFacebookF,
  faYoutube,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { useStore } from 'vuex';
import { DataSet } from 'vis-data';
import { Network } from 'vis-network';
import helper from '@/services/helper';
import api from '@/services/api';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import SourceTag from '../SourceTag.vue';
const networkGraphOption = {
  autoResize: true,
  height: '500px',
  width: '100%',
  interaction: {
    hover: true,
    navigationButtons: true,
    keyboard: {
      enabled: true,
      bindToWindow: false,
    },
  },
  edges: {
    smooth: {
      forceDirection: 'none',
      roundness: 0.4,
    },
    scaling: {
      min: 2,
      max: 10,
    },
  },
  nodes: {
    color: {
      highlight: {
        border: '#ff4d4d',
        background: '#ff4d4d',
      },
      hover: {
        border: '#99ffbb',
        background: '#99ffbb',
      },
    },
    scaling: {
      min: 25,
      max: 35,
      label: {
        min: 15,
        max: 18,
      },
    },
  },
  physics: {
    enabled: true,
    forceAtlas2Based: {
      gravitationalConstant: -50,
      centralGravity: 0.01,
      springConstant: 0.1,
      springLength: 80,
      damping: 0.5,
      avoidOverlap: 0.8,
    },
    minVelocity: 2,
  },
};
export default {
  name: 'TopInfluencerLists',
  components: {
    // SeeMoreDialog,
    SourceTag,
  },
  props: {
    influencerDataList: Array,
    filterResult: Object,
    sortBy: String,
  },
  setup(props) {
    const store = useStore();
    const { filterResult, influencerDataList: lists } = toRefs(props);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const limitCount = ref(10);
    const error = ref(false);
    const isNetworkModalOpen = ref(false);
    const infGraph = ref();
    const expandRowId = ref('');
    const offsetTypeWidth = computed(() => {
      const typeDiv = document.getElementById('InfluencerType');
      if (typeDiv) {
        return typeDiv.offsetWidth;
      } else {
        return 0;
      }
    });
    const toggleExpandRow = (id) => {
      if (expandRowId.value === id) {
        expandRowId.value = '';
      } else {
        expandRowId.value = id;
      }
    };
    const networkData = reactive({
      nodes: new DataSet(),
      edges: new DataSet(),
      loading: false,
      title: '',
      allParent: [],
      networkParent: null,
      edgeLimit: 50,
    });

    const showMessageModal = (userId, username) => {
      let f = {
        payload: {
          title: `Messages from "${username}"`,
        },
        criteria: {
          ...filterResult.value,
          userId,
          sort: {
            direction: 'desc',
            type: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
        type: 'influencer',
      };
      store.dispatch('message/showMessageModal', f);
    };

    const clickMenu = (id) => {
      const scrollElement = document.getElementById(id);
      // const scrollElement = document.getElementsByClassName('user-influencer-menu');
      if (scrollElement) {
        scrollElement.style.display = 'block';
      }
    };

    const htmlTitle = (html) => {
      const container = document.createElement('div');
      container.classList.add('influencer-tooltip');
      container.innerHTML = html;
      return container;
    };

    const setParent = (parent) => {
      networkData.title = `Network graph of "${parent.info.user_name || '-'}"`;
      let actions = parent.actions;
      let reactions = [];
      Object.keys(parent.engagement).forEach((engageKey) => {
        reactions.push({
          key: engageKey,
          value: parent.engagement[engageKey],
        });
      });

      let title =
        '' +
        '<div class="name">' +
        parent.username +
        '</div> <hr>' +
        '<div class="graph-info"><u> Influencer Score </u></div>' +
        '<div class="graph-info"> Influencer Score: &emsp;' +
        parent.node_score +
        '</div><hr>' +
        '<div align="center"><table><tr><td valign="top">' +
        '<div class="graph-info"><u> Actions </u></div>';
      for (let k in actions) {
        title +=
          '<div class="graph-info">  <span>' +
          actions[k].key +
          '</span>&emsp;: &emsp;' +
          actions[k].value +
          '</div>';
      }
      title +=
        '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
      for (let l in reactions) {
        title +=
          '<div class="graph-info">  <span>' +
          reactions[l].key +
          '</span>&emsp;: &emsp;' +
          reactions[l].value +
          '</div>';
      }
      title += '</td></tr></table></div>';
      console.log(parent);

      const img = parent.img || parent.newProfileImage;
      networkData.nodes.update({
        id: parent.id,
        color: '#3d3',
        label: parent.username,
        shape: 'circularImage',
        image: img,
        title: htmlTitle(title),
        value: 2,
      });
    };
    const setChild = async (parent) => {
      const networkArg = {
        ...filterResult.value,
        nodeLimit: 50,
        edgeLimit: networkData.edgeLimit,
      };
      let parentID = parent.id;
      const result = await api
        .getInfluencerChildNode(true, parentID, networkArg)
        .catch(() => {
          console.log('ERRR');
        });
      let childlist = result.message;
      let arr = [];
      for (let i in childlist.children) {
        let id = childlist.children[i].id;
        let actions = childlist.children[i].actions;
        let reactions = childlist.children[i].reactions;
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          childlist.peopleInfo[id].user_name +
          '</div> <hr>' +
          '<div class="graph-info"><u> Influencer Score </u></div>' +
          '<div class="graph-info"> Influencer Score: &emsp;' +
          childlist.children[i].node_score +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Actions </u></div>';
        for (let k in actions) {
          title +=
            '<div class="graph-info">  <span>' +
            actions[k].key +
            '</span>&emsp;: &emsp;' +
            actions[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
        for (let l in reactions) {
          title +=
            '<div class="graph-info">  <span>' +
            reactions[l].key +
            '</span>&emsp;: &emsp;' +
            reactions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        try {
          arr.push({
            id: childlist.children[i].id,
            shape: 'circularImage',
            image: childlist.peopleInfo[id].user_photo,
            label: childlist.peopleInfo[id].user_name,
            title: htmlTitle(title),
            value: 1,
          });
        } catch (e) {
          console.error('Cannot get info', id, e);
        }
      }
      networkData.nodes.update(arr);
      for (let j in childlist.relationship) {
        let node = false;
        let from = childlist.relationship[j].from;
        node = networkData.nodes.get(from);
        if (node) {
          from = node.label;
        }
        node = false;
        let to = childlist.relationship[j].to;
        node = networkData.nodes.get(to);
        if (node) {
          to = node.label;
        }
        childlist.relationship[j].arrows = 'to';
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          from +
          '  &#8594;  ' +
          to +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Sentiment Info </u></div>';
        for (let k in childlist.relationship[j].sentiment_count) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].sentiment_count[k].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].sentiment_count[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Actions </u></div>';
        for (let l in childlist.relationship[j].actions) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].actions[l].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].actions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        childlist.relationship[j].title = htmlTitle(title);
        childlist.relationship[j].value = childlist.relationship[j].edge_score;
        childlist.relationship[j].color = {
          color: '#69a3c5',
          hover: '#55F6F0',
          highlight: '#3CEE40',
          opacity: 0.9,
        };
        childlist.relationship[j].id =
          childlist.relationship[j].from + '' + childlist.relationship[j].to;
      }
      networkData.edges.update(childlist.relationship);
      networkData.loading = false;
    };

    const showNetworkGraph = async (parent) => {
      isNetworkModalOpen.value = true;
      networkData.loading = true;
      networkData.allParent = [];
      networkData.networkParent = parent;
      networkData.nodes.clear();
      networkData.edges.clear();
      setParent(parent);
      await setChild(parent);
      // console.log(infGraph.value, networkData.nodes, networkData.edges, Network, networkGraphOption);
      new Network(
        infGraph.value,
        { nodes: networkData.nodes, edges: networkData.edges },
        networkGraphOption,
      );
    };
    const onNetworkModalCancel = () => {
      console.log('OHH');
    };

    const openUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        helper.openUrl(item.info.user_link);
      }
    };

    const copyUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        helper.copyUrl(item.info.user_link);
      }
    };

    const onImageError = (e, value) => {
      if (value.source === 'facebook') {
        if (value.fallbackImage && e.target.src !== value.fallbackImage) {
          e.target.src = value.fallbackImage;
        } else {
          const img = helper.getDefaultImageProfile(value.source);
          e.target.src = img;
        }
      } else {
        const img = helper.getDefaultImageProfile(value.source);
        e.target.src = img;
      }
    };

    provide('showMode', '');

    const onScroll = () => {
      const scrollElement = document.getElementsByClassName(
        'user-influencer-menu',
      );
      if (scrollElement) {
        for (let ele of scrollElement) {
          // ele.remove();
          ele.style.display = 'none';
        }
      }
    };

    const checkStr = (str) => {
      return str.length >= 10;
    };

    return {
      error,
      clickMenu,
      openUserLink,
      onNetworkModalCancel,
      copyUserLink,
      showNetworkGraph,
      onImageError,
      showMessageModal,
      onScroll,
      toggleExpandRow,
      expandRowId,
      limitCount,
      lists,
      faFacebookF,
      faYoutube,
      faTwitter,
      faInstagram,
      isMobileScreen,
      offsetTypeWidth,
      checkStr,
      networkData,
      infGraph,
      isNetworkModalOpen,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-border {
  border-bottom: 1px solid #f1f2f6;
}
.action-list {
  padding-top: 12px;
  padding-bottom: 4px;
  .title {
    font-size: 14px;
    color: #5a5f7d;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
    @media screen and (max-width: 356px) {
      font-size: 12px;
    }
  }
  .action-row {
    display: flex;
    justify-content: space-between;
    line-height: 16px;
    margin-bottom: 8px;
    .action-title {
      color: #9299b8;
      font-size: 12px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 356px) {
        font-size: 10px !important;
      }
      i {
        margin-right: 5px;
      }
    }
    .action-value {
      color: #5a5f7d;
      @media screen and (max-width: 356px) {
        font-size: 11px;
      }
    }
  }
}

.total-list {
  border: 1px solid #f1f2f6;
  padding: 16px;
  background-color: #f8f9fb;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 16px;
  border-radius: 5px;
  .total-block {
    text-align: center;
    .value-row {
      font-size: 16px;
      font-weight: 500;
      color: #272b41;
      margin-bottom: 6px;
    }
    .unit-row {
      font-size: 13px;
      color: #9299b8;
      text-transform: capitalize;
    }
  }
}

.item-table {
  width: 100%;
  overflow: auto;
  display: block;
  @media screen and (max-width: 750px) {
    display: none;
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 16px;
    width: 100%;
    td:first-child {
      border-right: none;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .item-row {
    cursor: pointer;
    td {
      padding: 20px 18px;
      background-color: #fff;
      color: #5a5f7d;
      vertical-align: top;
      border-right: 1px solid #f1f2f6;
      @media screen and (max-width: 1130px) {
        padding: 15px 14px;
      }
      @media screen and (max-width: 1030px) {
        padding: 10px 9px;
        border-right: none;
      }

      &.border-none {
        border-right: none;
      }
      &.middle {
        vertical-align: middle;
      }
      &.top-index {
        width: 36px;
        height: 36px;
        margin-left: 25px;
        img {
          width: 36px;
          height: 36px;
        }
      }
    }
    .user-photo-wrap {
      position: relative;
      user-select: none;
      text-align: center;
      width: 64px;
      height: 64px;
      @media screen and (max-width: 1130px) {
        width: 54px;
        height: 54px;
      }
      @media screen and (max-width: 1060px) {
        width: 44px;
        height: 44px;
      }
      .user-photo {
        width: 64px;
        height: 64px;
        border-radius: 50px;
        @media screen and (max-width: 1130px) {
          width: 54px;
          height: 54px;
        }
        @media screen and (max-width: 1060px) {
          width: 44px;
          height: 44px;
        }
      }
      .source-tag {
        position: absolute;
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
        .logo {
          height: 16px;
          width: 16px;
          border-radius: 12px;
          color: #fff;
          margin-right: 4px;
          line-height: 14px;
          overflow: hidden;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #252525;
        }
        .inner {
          display: flex;
        }
        &.facebook {
          .logo {
            background-color: rgba(24, 119, 242, 1);
            svg {
              height: 10px;
            }
          }
        }
        &.twitter {
          .logo {
            background-color: rgb(34, 164, 240);
            svg {
              height: 10px;
            }
          }
        }
        &.twitterv2 {
          .logo {
            background-color: rgb(34, 164, 240);
            svg {
              height: 10px;
            }
          }
        }
        &.pantip {
          .logo {
            background-color: rgba(118, 116, 188, 1);
            svg {
              height: 12px;
            }
          }
        }
        &.youtube {
          .logo {
            background-color: #ff0000;
            svg {
              height: 10px;
            }
          }
        }
        &.instagram {
          .logo {
            background-color: rgba(228, 64, 95, 1);
            svg {
              height: 10px;
            }
          }
        }
        &.blockdit {
          .logo {
            background-color: #4a69ff;
            .blockdit {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .user-info {
      margin-left: 16px;
      max-width: 200px;
      @media screen and (max-width: 1130px) {
        max-width: 150px;
      }
      .username {
        line-height: 18px;
        color: #272b41;
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .account {
        margin-bottom: 12px;
        margin-top: 4px;
        line-height: 16px;
        color: #9299b8;
        font-weight: 400;
        font-size: 13px;
      }
    }
    .type-row {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: fit-content;
      text-align: center;
      justify-content: left;
      column-gap: 8px;
      row-gap: 8px;
      @media screen and (max-width: 1585px) {
        flex-wrap: wrap;
      }
      .type {
        border-radius: 4px;
        height: 20px;
        line-height: 12px;
        font-size: 10px;
        font-weight: 400;
        text-transform: capitalize;
        padding: 4px 8px;
        white-space: nowrap;
        color: #5a5f7d;
        // display: inline-block;
        display: flex;
        align-items: center;
        background-color: #ebf1ff;
      }
    }

    .type-row-empty {
      height: 20px;
    }

    .unit-text {
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #272b41;
      margin-bottom: 8px;
      @media screen and (max-width: 1060px) {
        font-size: 11px;
        line-height: 14px;
      }
      @media screen and (max-width: 991px) {
        font-size: 13px;
        line-height: 16px;
      }
      @media screen and (max-width: 830px) {
        font-size: 11px;
        line-height: 14px;
      }
    }

    .value-text {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      @media screen and (max-width: 1060px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media screen and (max-width: 1030px) {
        font-size: 12px;
        line-height: 16px;
      }
      @media screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .engagement-row {
      display: flex;
      align-items: center;
      margin: 16px 0 0;
      line-height: 16px;
      color: #5a5f7d;
      font-weight: 400;
      font-size: 12px;
      @media screen and (max-width: 1280px) {
        display: block;
      }
      @media screen and (max-width: 1030px) {
        font-size: 10px;
        line-height: 14px;
      }
      @media screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 16px;
      }
      .engagement {
        display: flex;
        vertical-align: middle;
        align-items: center;
        .engagement-icon {
          padding-right: 8px;
        }
        @media screen and (max-width: 1280px) {
          margin: 8px 0;
        }
      }
      .first {
        margin-right: 24px;
        @media screen and (max-width: 1370px) {
          margin-right: 12px;
        }
      }
    }

    .sentiment-list {
      padding-bottom: 16px;
      .sentiment-block {
        margin: 8px 0 0;
      }
      .sentiment-block,
      .sentiment-value {
        display: flex;
        align-items: center;
        .sentiment-circle {
          padding: 3px;
          border-radius: 24px;
          height: 16px;
          width: 16px;
          overflow: hidden;
          text-align: center;
          margin-right: 4px;
          line-height: 10px;
          &.positive {
            background-color: rgba(32, 201, 151, 0.15);
            color: #20c997;
          }
          &.neutral {
            background-color: #fff5e2;
            color: #febc3c;
          }
          &.negative {
            background-color: rgba(255, 77, 79, 0.15);
            color: #ff4d4f;
          }
        }
        .value {
          font-size: 12px;
          color: #5a5f7d;
          @media screen and (max-width: 1030px) {
            font-size: 10px;
          }
          @media screen and (max-width: 991px) {
            font-size: 12px;
          }
          &.zero {
            color: #adbcd2;
          }
        }
      }
      .view {
        display: flex;
        vertical-align: middle;
        align-items: center;
        font-size: 12px;
        color: #5a5f7d;
        .view-icon {
          padding-right: 8px;
        }
      }
    }
    .action-row {
      display: flex;
      .action-title {
        color: #9299b8;
      }
      .action-value {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }
  .action-list {
    padding-top: 12px;
    padding-bottom: 4px;
    .title {
      font-size: 14px;
      color: #5a5f7d;
      font-weight: 500;
      text-align: left;
      margin-bottom: 8px;
    }
    .action-row {
      display: flex;
      justify-content: space-between;
      line-height: 16px;
      margin-bottom: 8px;
      .action-title {
        color: #9299b8;
        font-size: 12px;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .action-value {
        color: #5a5f7d;
      }
    }
  }
  .no-bordersmall {
    border-top: none;
  }
}

.mobile-table {
  display: none;
  @media screen and (max-width: 750px) {
    display: block;
  }
  .table {
    width: 100%;
  }
  .item-row {
    border-top: 8px solid #f0f2f5;
    td {
      cursor: pointer;
      background-color: #fff;
      color: #5a5f7d;
      &.middle {
        vertical-align: middle;
      }
      &.top {
        vertical-align: top;
      }
    }
    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &.expand {
      td {
        border-bottom: none;
      }
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0;
      }
      td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 0;
      }
    }
    .top-index {
      width: 28px;
      height: 28px;
      margin-left: 25px;
      padding: 16px 4px 0 16px;
      vertical-align: top;
      @media screen and (max-width: 376px) {
        padding: 16px 4px 0 8px;
      }
      img {
        width: 28px;
        height: 28px;
      }
    }

    .index-td {
      padding: 16px 4px 0 16px;
      vertical-align: top;
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
      @media screen and (max-width: 376px) {
        padding: 16px 4px 0 8px;
      }
    }
    .info-td {
      padding: 16px 0 0 0;
      @media screen and (max-width: 450px) {
        padding: 16px 0;
      }
    }
    .engagement-td {
      padding: 16px 10px 0 0;
      @media screen and (max-width: 376px) {
        padding: 16px 5px 0 0;
      }
    }
    .menu-td {
      padding: 16px 16px 0 0;
      @media screen and (max-width: 376px) {
        padding: 16px 8px 0 0;
      }
    }
    .user-photo-wrap {
      position: relative;
      user-select: none;
      text-align: center;
      width: 32px;
      height: 32px;
      .user-photo {
        width: 32px;
        height: 32px;
        border-radius: 50px;
      }
      .source-tag {
        position: absolute;
        right: -5px;
        top: 20px;
        left: auto;
        bottom: 0;
        .logo {
          height: 12px;
          width: 12px;
          border-radius: 12px;
          color: #fff;
          margin-right: 4px;
          line-height: 14px;
          overflow: hidden;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #252525;
        }
        .inner {
          display: flex;
        }
        &.facebook {
          .logo {
            background-color: rgba(24, 119, 242, 1);
            svg {
              height: 10px;
            }
          }
        }
        &.twitter {
          .logo {
            background-color: rgb(34, 164, 240);
            svg {
              height: 10px;
            }
          }
        }
        &.twitterv2 {
          .logo {
            background-color: rgb(34, 164, 240);
            svg {
              height: 10px;
            }
          }
        }
        &.pantip {
          .logo {
            background-color: rgba(118, 116, 188, 1);
            svg {
              height: 12px;
            }
          }
        }
        &.youtube {
          .logo {
            background-color: #ff0000;
            svg {
              height: 10px;
            }
          }
        }
        &.instagram {
          .logo {
            background-color: rgba(228, 64, 95, 1);
            svg {
              height: 10px;
            }
          }
        }
        &.blockdit {
          .logo {
            background-color: #4a69ff;
            .blockdit {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .user-info {
      margin-left: 16px;
      max-width: 200px;
      .username {
        line-height: 14px;
        color: #272b41;
        font-weight: 500;
        font-size: 11px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 4px;
      }
      .account {
        margin-bottom: 12px;
        margin-top: 4px;
        line-height: 16px;
        color: #9299b8;
        font-weight: 400;
        font-size: 11px;
      }
    }
    .type-row {
      overflow: hidden;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: fit-content;
      margin: 0 auto;
      text-align: center;
      justify-content: left;
      column-gap: 8px;
      row-gap: 8px;
      @media screen and (max-width: 690px) {
        margin: 0;
      }
      @media screen and (max-width: 450px) {
        flex-wrap: wrap;
        width: 100%;
      }
      .type {
        border-radius: 4px;
        height: 20px;
        line-height: 12px;
        font-size: 11px;
        font-weight: 400;
        text-transform: capitalize;
        padding: 4px 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #5a5f7d;
        background-color: #ebf1ff;
        .tooltip-mobile {
          display: none;
        }
        @media screen and (max-width: 690px) {
          max-width: 64px;
          overflow: hidden;
          .tooltip-mobile {
            display: block;
          }
        }
        @media screen and (max-width: 356px) {
          padding: 4px;
          line-height: 10px;
          font-size: 10px;
          height: 15px;
        }
      }
    }
    .type-row-empty {
      height: 20px;
    }

    .unit-text {
      padding-top: 11px;
      font-size: 11px;
      line-height: 16px;
      font-weight: 400;
      color: #272b41;
      margin-bottom: 8px;
    }

    .value-text {
      font-size: 13px;
      line-height: 20px;
      font-weight: 700;
    }

    .influencer-row {
      display: flex;
      align-items: center;
      margin: 8px 0 0;
      line-height: 16px;
      color: #5a5f7d;
      font-weight: 400;
      font-size: 11px;
      @media screen and (max-width: 356px) {
        display: block;
      }
      .influencer {
        display: flex;
        vertical-align: middle;
        align-items: center;
        .influencer-icon {
          padding-right: 8px;
        }
      }
      .first {
        margin-right: 24px;
        @media screen and (max-width: 356px) {
          margin-right: 0;
        }
      }
    }

    .menu-item {
      margin-bottom: 26px;
      color: #9299b8;
    }
    .expand-item i {
      color: #9299b8;
    }
  }
  .expand-row {
    border-bottom: 8px solid #f0f2f5;
    td {
      padding: 10px 16px;
      background-color: #fff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .no-bordersmall {
      border-bottom: none !important;
    }
    .action-list {
      border-bottom: 1px solid #f1f2f6;
      font-size: 13px;
      .action-title {
        color: #9299b8;
        font-size: 14px;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .action-value {
        color: #5a5f7d;
      }
    }
  }
  .expand-total {
    border-top: 1px solid #f1f2f6;
  }
  .total-list {
    margin: 12px 0 0;
    padding: 12px;
    @media screen and (max-width: 356px) {
      padding: 6px;
    }
    .total-block {
      text-align: center;
      .value-row {
        font-size: 13px;
        line-height: 19px;
        font-weight: 500;
        color: #272b41;
        margin-bottom: 4px;
        @media screen and (max-width: 356px) {
          font-size: 11px;
          line-height: 16px;
        }
      }
      .unit-row {
        font-size: 11px;
        line-height: 16px;
        color: #9299b8;
        text-transform: capitalize;
        @media screen and (max-width: 356px) {
          font-size: 9px;
          line-height: 14px;
        }
      }
    }
  }
}
.blue-text {
  color: #3371ff;
}

.text-center {
  margin: 36px 0 0 0;
}

.source-tag {
  line-height: 13px;
  color: #434870;
  font-weight: 500;
  font-size: 10px;
}

@media only screen and (max-width: 580px) {
  .source-row {
    justify-content: unset;
  }
  .text-center {
    margin: 23px 0 0 0;
  }
  .item-table {
    .item-row {
      td {
        &.gold-text,
        &.silver-text,
        &.bronze-text {
          width: 24px;
          height: 24px;
          margin-left: unset;
        }
      }
    }
  }
}
</style>
<style scoped>
.ant-tooltip .ant-tooltip-inner {
  padding: 6px 10px;
}
.ant-tooltip .ant-tooltip-inner .type-row {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
  text-align: left;
  justify-content: left;
  column-gap: 8px;
  row-gap: 8px;
}

.ant-tooltip .ant-tooltip-inner .type-row .type {
  border-radius: 4px;
  height: 20px;
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 4px 8px;
  white-space: nowrap;
  color: #5a5f7d;
  display: flex;
  align-items: center;
  background-color: #ebf1ff;
}
</style>
