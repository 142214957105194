<template>
  <a-row
    class="big-item-row"
    :class="{
      'big-item-row-min': lists.length === 2,
      'big-item-row-only': lists.length === 1,
    }"
  >
    <a-col
      v-for="(item, index) in lists"
      :key="item.id"
      class="big-item"
      @click="showMessageModal(item.id, item.info.user_name)"
    >
      <a-card class="grid-card">
        <div class="float-bar">
          <div v-if="index <= 2">
            <img
              v-if="index === 0"
              :src="require('@/assets/images/icon/01-medal-gold.svg')"
            />
            <img
              v-if="index === 1"
              :src="require('@/assets/images/icon/02-medal-silver.svg')"
            />
            <img
              v-if="index === 2"
              :src="require('@/assets/images/icon/03-medal-bronze.svg')"
            />
          </div>
          <div
            v-else
            class="number-flag"
          >
            {{ index + 1 }}
          </div>
          <div class="user-menu">
            <a-dropdown
              class="user-menu-wrap"
              :placement="'bottomRight'"
              trigger="click"
            >
              <div
                class="user-menu-button"
                @click.stop="clickMenu(`top3-dropdown-${index}`)"
              >
                <FeatherIcon
                  type="more-horizontal"
                  :size="16"
                />
              </div>
              <template #overlay>
                <a-menu
                  :id="`top3-dropdown-${index}`"
                  class="user-influencer-menu"
                >
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="openUserLink(item)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="external-link" />
                      Open Link
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="copyUserLink(item)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="copy" />
                      Copy Link
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="showMessageModal(item.id, item.info.user_name)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="eye" />
                      View Message
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    class="user-menu-item-wrapper"
                    @click="showNetworkGraph(item)"
                  >
                    <a class="user-menu-item">
                      <FeatherIcon type="share-2" />
                      Network Graph
                    </a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
        <div class="user-photo-wrap">
          <img
            class="user-photo"
            :src="item.newProfileImage"
            @error="onImageError($event, item)"
          />
          <SourceTag
            :source="item.source"
            :showLabel="false"
          ></SourceTag>
        </div>
        <div class="username">
          {{ item.info.user_name }}
        </div>
        <div class="account">
          {{ '@' + item.info.user_short_id }}
        </div>
        <div
          v-if="item.tooltip.length > 0 && item.types.length !== 0"
          id="InfluencerType"
          class="type-row"
        >
          <div
            v-for="(item, index) in item.showTag"
            :key="index"
            class="type"
          >
            {{ item }}
          </div>
          <a-tooltip placement="bottom">
            <template #title>
              <div class="type-row">
                <div
                  v-for="(item, index) in item.tooltip"
                  :key="index"
                  class="type"
                >
                  {{ item }}
                </div>
              </div>
            </template>
            <div class="type more-item">{{ item.moreItem }}</div>
          </a-tooltip>
        </div>
        <div
          v-else-if="item.tooltip.length === 0 && item.types.length !== 0"
          id="InfluencerTypeAll"
          class="type-row"
        >
          <div
            v-for="(item, index) in item.types"
            :key="index"
            class="type"
          >
            {{ item }}
          </div>
        </div>
        <div
          v-else-if="item.types.length === 0"
          class="type-row-empty"
        ></div>
        <div class="engagement-row">
          <!-- <div class="engagement">
            <div class="label">
              <FeatherIcon class="engagement-icon" type="users" :size="16" />
              <span class="engagement-label">Followers</span>
            </div>
            <div class="label">
              <span class="engagement-value">7.3M</span>
            </div>
          </div>
          <div class="engagement">
            <div class="label">
              <FeatherIcon class="engagement-icon" type="pie-chart" :size="16" />
              <span class="engagement-label">Engagement Rate</span>
            </div>
            <div class="label">
              <span class="engagement-value">2.1%</span>
            </div>
          </div> -->
        </div>
        <div class="influencer-divide"></div>
        <div class="sentiment-value">
          <div class="sentiment-list">
            <div class="sentiment-block">
              <div class="sentiment-circle positive">
                <FeatherIcon
                  :type="'smile'"
                  :size="14"
                />
              </div>
              <div
                class="value"
                :class="{ zero: item.sentiment.positive === 0 }"
              >
                {{ item.sentiment.positive }}
              </div>
            </div>
            <div class="sentiment-block">
              <div class="sentiment-circle neutral">
                <FeatherIcon
                  :type="'meh'"
                  :size="14"
                />
              </div>
              <div
                class="value"
                :class="{ zero: item.sentiment.neutral === 0 }"
              >
                {{ item.sentiment.neutral }}
              </div>
            </div>
            <div class="sentiment-block">
              <div class="sentiment-circle negative">
                <FeatherIcon
                  :type="'frown'"
                  :size="14"
                />
              </div>
              <div
                class="value"
                :class="{ zero: item.sentiment.negative === 0 }"
              >
                {{ item.sentiment.negative }}
              </div>
            </div>
          </div>
        </div>
        <div class="total-list">
          <div class="total-block">
            <div
              class="value-row"
              :class="{ 'blue-text': sortBy === 'engagement' }"
            >
              {{ item.totalEngage }}
            </div>
            <div class="unit-row">Engagement</div>
          </div>
          <div class="total-block">
            <div
              class="value-row"
              :class="{ 'blue-text': sortBy === 'count' }"
            >
              {{ item.totalMention }}
            </div>
            <div class="unit-row">Mentions</div>
          </div>
          <div class="total-block">
            <div
              class="value-row"
              :class="{ 'blue-text': sortBy === 'view_count' }"
            >
              {{ item.engagement.view || 0 }}
            </div>
            <div class="unit-row">Views</div>
          </div>
        </div>
      </a-card>
    </a-col>
    <!-- <SeeMoreDialog /> -->
  </a-row>
  <a-modal
    v-model:visible="isNetworkModalOpen"
    :title="networkData.title"
    :footer="null"
    width="838px"
    :z-index="1000"
  >
    <div
      v-if="networkData.loading"
      class="loading-wrap"
    >
      <a-spin />
    </div>
    <div
      v-show="!networkData.loading"
      id="influencer-graph"
      ref="infGraph"
    ></div>
  </a-modal>
</template>

<script>
import { ref, reactive, provide, toRefs, computed } from 'vue';
import {
  faFacebookF,
  faYoutube,
  faXTwitter,
  faInstagram,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { useStore } from 'vuex';
import { DataSet } from 'vis-data';
import { Network } from 'vis-network';
import helper from '@/services/helper';
import api from '@/services/api';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import SourceTag from '@/components/SourceTag.vue';
const networkGraphOption = {
  autoResize: true,
  height: '500px',
  width: '100%',
  interaction: {
    hover: true,
    navigationButtons: true,
    keyboard: {
      enabled: true,
      bindToWindow: false,
    },
  },
  edges: {
    smooth: {
      forceDirection: 'none',
      roundness: 0.4,
    },
    scaling: {
      min: 2,
      max: 10,
    },
  },
  nodes: {
    color: {
      highlight: {
        border: '#ff4d4d',
        background: '#ff4d4d',
      },
      hover: {
        border: '#99ffbb',
        background: '#99ffbb',
      },
    },
    scaling: {
      min: 25,
      max: 35,
      label: {
        min: 15,
        max: 18,
      },
    },
  },
  physics: {
    enabled: true,
    forceAtlas2Based: {
      gravitationalConstant: -50,
      centralGravity: 0.01,
      springConstant: 0.1,
      springLength: 80,
      damping: 0.5,
      avoidOverlap: 0.8,
    },
    minVelocity: 2,
  },
};
export default {
  name: 'InfluencerGrids',
  components: {
    // SeeMoreDialog,
    SourceTag,
  },
  props: {
    influencerDataList: Array,
    filterResult: Object,
    sortBy: String,
  },
  setup(props) {
    const store = useStore();
    const { filterResult, influencerDataList: lists } = toRefs(props);
    const limitCount = ref(10);
    const error = ref(false);
    const isNetworkModalOpen = ref(false);
    const infGraph = ref();
    const expandRowId = ref('');
    const offsetTypeWidth = computed(() => {
      const typeDiv = document.getElementById('InfluencerType');
      if (typeDiv) {
        return typeDiv.offsetWidth;
      } else {
        return 0;
      }
    });

    const toggleExpandRow = (id) => {
      if (expandRowId.value === id) {
        expandRowId.value = '';
      } else {
        expandRowId.value = id;
      }
    };
    const networkData = reactive({
      nodes: new DataSet(),
      edges: new DataSet(),
      loading: false,
      title: '',
      allParent: [],
      networkParent: null,
      edgeLimit: 50,
    });

    const showMessageModal = (userId, username) => {
      let f = {
        payload: {
          title: `Messages from "${username}"`,
        },
        criteria: {
          ...filterResult.value,
          userId,
          sort: {
            direction: 'desc',
            type: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
        type: 'influencer',
      };
      store.dispatch('message/showMessageModal', f);
    };

    const clickMenu = (id) => {
      const scrollElement = document.getElementById(id);
      // const scrollElement = document.getElementsByClassName('user-influencer-menu');
      if (scrollElement) {
        scrollElement.style.display = 'block';
      }
    };

    const htmlTitle = (html) => {
      const container = document.createElement('div');
      container.classList.add('influencer-tooltip');
      container.innerHTML = html;
      return container;
    };

    const setParent = (parent) => {
      networkData.title = `Network graph of "${parent.info.user_name || '-'}"`;
      let actions = parent.actions;
      let reactions = [];
      Object.keys(parent.engagement).forEach((engageKey) => {
        reactions.push({
          key: engageKey,
          value: parent.engagement[engageKey],
        });
      });

      let title =
        '' +
        '<div class="name">' +
        parent.username +
        '</div> <hr>' +
        '<div class="graph-info"><u> Influencer Score </u></div>' +
        '<div class="graph-info"> Influencer Score: &emsp;' +
        parent.node_score +
        '</div><hr>' +
        '<div align="center"><table><tr><td valign="top">' +
        '<div class="graph-info"><u> Actions </u></div>';
      for (let k in actions) {
        title +=
          '<div class="graph-info">  <span>' +
          actions[k].key +
          '</span>&emsp;: &emsp;' +
          actions[k].value +
          '</div>';
      }
      title +=
        '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
      for (let l in reactions) {
        title +=
          '<div class="graph-info">  <span>' +
          reactions[l].key +
          '</span>&emsp;: &emsp;' +
          reactions[l].value +
          '</div>';
      }
      title += '</td></tr></table></div>';
      console.log(parent);

      const img = parent.img || parent.newProfileImage;
      networkData.nodes.update({
        id: parent.id,
        color: '#3d3',
        label: parent.username,
        shape: 'circularImage',
        image: img,
        title: htmlTitle(title),
        value: 2,
      });
    };
    const setChild = async (parent) => {
      const networkArg = {
        ...filterResult.value,
        nodeLimit: 50,
        edgeLimit: networkData.edgeLimit,
      };
      let parentID = parent.id;
      const result = await api
        .getInfluencerChildNode(true, parentID, networkArg)
        .catch(() => {
          console.log('ERRR');
        });
      let childlist = result.message;
      let arr = [];
      for (let i in childlist.children) {
        let id = childlist.children[i].id;
        let actions = childlist.children[i].actions;
        let reactions = childlist.children[i].reactions;
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          childlist.peopleInfo[id].user_name +
          '</div> <hr>' +
          '<div class="graph-info"><u> Influencer Score </u></div>' +
          '<div class="graph-info"> Influencer Score: &emsp;' +
          childlist.children[i].node_score +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Actions </u></div>';
        for (let k in actions) {
          title +=
            '<div class="graph-info">  <span>' +
            actions[k].key +
            '</span>&emsp;: &emsp;' +
            actions[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
        for (let l in reactions) {
          title +=
            '<div class="graph-info">  <span>' +
            reactions[l].key +
            '</span>&emsp;: &emsp;' +
            reactions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        try {
          arr.push({
            id: childlist.children[i].id,
            shape: 'circularImage',
            image: childlist.peopleInfo[id].user_photo,
            label: childlist.peopleInfo[id].user_name,
            title: htmlTitle(title),
            value: 1,
          });
        } catch (e) {
          console.error('Cannot get info', id, e);
        }
      }
      networkData.nodes.update(arr);
      for (let j in childlist.relationship) {
        let node = false;
        let from = childlist.relationship[j].from;
        node = networkData.nodes.get(from);
        if (node) {
          from = node.label;
        }
        node = false;
        let to = childlist.relationship[j].to;
        node = networkData.nodes.get(to);
        if (node) {
          to = node.label;
        }
        childlist.relationship[j].arrows = 'to';
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          from +
          '  &#8594;  ' +
          to +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Sentiment Info </u></div>';
        for (let k in childlist.relationship[j].sentiment_count) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].sentiment_count[k].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].sentiment_count[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Actions </u></div>';
        for (let l in childlist.relationship[j].actions) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].actions[l].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].actions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        childlist.relationship[j].title = htmlTitle(title);
        childlist.relationship[j].value = childlist.relationship[j].edge_score;
        childlist.relationship[j].color = {
          color: '#69a3c5',
          hover: '#55F6F0',
          highlight: '#3CEE40',
          opacity: 0.9,
        };
        childlist.relationship[j].id =
          childlist.relationship[j].from + '' + childlist.relationship[j].to;
      }
      networkData.edges.update(childlist.relationship);
      networkData.loading = false;
    };

    const showNetworkGraph = async (parent) => {
      isNetworkModalOpen.value = true;
      networkData.loading = true;
      networkData.allParent = [];
      networkData.networkParent = parent;
      networkData.nodes.clear();
      networkData.edges.clear();
      setParent(parent);
      await setChild(parent);
      // console.log(infGraph.value, networkData.nodes, networkData.edges, Network, networkGraphOption);

      new Network(
        infGraph.value,
        { nodes: networkData.nodes, edges: networkData.edges },
        networkGraphOption,
      );
    };
    const onNetworkModalCancel = () => {
      console.log('OHH');
    };

    const openUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        helper.openUrl(item.info.user_link);
      }
    };

    const copyUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        helper.copyUrl(item.info.user_link);
      }
    };

    const onImageError = (e, value) => {
      if (value.source === 'facebook') {
        if (value.fallbackImage && e.target.src !== value.fallbackImage) {
          e.target.src = value.fallbackImage;
        } else {
          const img = helper.getDefaultImageProfile(value.source);
          e.target.src = img;
        }
      } else {
        const img = helper.getDefaultImageProfile(value.source);
        e.target.src = img;
      }
    };

    provide('showMode', '');

    const onScroll = () => {
      const scrollElement = document.getElementsByClassName(
        'user-influencer-menu',
      );
      if (scrollElement) {
        for (let ele of scrollElement) {
          // ele.remove();
          ele.style.display = 'none';
        }
      }
    };

    return {
      error,
      clickMenu,
      openUserLink,
      onNetworkModalCancel,
      copyUserLink,
      showNetworkGraph,
      onImageError,
      showMessageModal,
      onScroll,
      toggleExpandRow,
      expandRowId,
      limitCount,
      lists,
      faFacebookF,
      faYoutube,
      faXTwitter,
      faInstagram,
      offsetTypeWidth,
      faTiktok,
      networkData,
      isNetworkModalOpen,
      infGraph,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-border {
  border-bottom: 1px solid #f1f2f6;
}
.action-list {
  padding-top: 12px;
  padding-bottom: 4px;
  .title {
    font-size: 14px;
    color: #5a5f7d;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
  }
  .action-row {
    display: flex;
    justify-content: space-between;
    line-height: 16px;
    margin-bottom: 8px;
    .action-title {
      color: #9299b8;
      font-size: 12px;
      display: flex;
      align-items: center;
      i {
        margin-right: 5px;
      }
    }
    .action-value {
      color: #5a5f7d;
    }
  }
}
.big-item-row-only {
  grid-template-columns: none !important;
  max-width: 260px;
}
.big-item-row-min {
  grid-template-columns:
    minmax(260px, max-content)
    repeat(auto-fit, 260px) !important;
}
.big-item-row {
  margin-bottom: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: 16px 20px !important;
  @media screen and (max-width: 690px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .big-item {
    padding: 0 !important;
    @media screen and (max-width: 690px) {
      margin: 0;
    }
    .grid-card {
      min-width: 260px;
      background-color: #fff;
      box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.031);
      // min-height: 590px;
      text-align: center;
      position: relative;
      .float-bar {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        top: 0;
        left: 0;
        padding: 0 16px;
        .number-flag {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          color: #272b41;
          padding-top: 16px;
        }
        .user-menu {
          padding-top: 16px;
          .user-menu-button {
            cursor: pointer;
          }
          i {
            color: #adb4d2;
          }
        }
      }
      .user-photo-wrap {
        position: relative;
        user-select: none;
        text-align: center;
        width: 80px;
        height: 80px;
        margin: 20px auto 8px;
        .user-photo {
          width: 80px;
          height: 80px;
          border-radius: 50px;
        }
        .source-tag {
          position: absolute;
          top: auto;
          left: auto;
          bottom: 4px;
          right: 4px;
          .logo {
            height: 16px;
            width: 16px;
            border-radius: 12px;
            color: #fff;
            margin-right: 4px;
            line-height: 14px;
            overflow: hidden;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #252525;
          }
          .inner {
            display: flex;
          }
          &.facebook {
            .logo {
              background-color: rgba(24, 119, 242, 1);
              svg {
                height: 10px;
              }
            }
          }
          &.twitter {
            .logo {
              background-color: #000000;
              svg {
                height: 10px;
              }
            }
          }
          &.pantip {
            .logo {
              background-color: rgba(118, 116, 188, 1);
              svg {
                height: 12px;
              }
            }
          }
          &.youtube {
            .logo {
              background-color: #ff0000;
              svg {
                height: 10px;
              }
            }
          }
          &.instagram {
            .logo {
              background-color: rgba(228, 64, 95, 1);
              svg {
                height: 10px;
              }
            }
          }
          &.blockdit {
            .logo {
              background-color: #4a69ff;
              .blockdit {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
      .username {
        max-width: 250px;
        line-height: 20px;
        color: #272b41;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        margin: 0 auto;
      }
      .account {
        margin-bottom: 24px;
        margin-top: 4px;
        line-height: 16px;
        color: #9299b8;
        font-weight: 400;
        font-size: 13px;
      }
      .type-row {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: fit-content;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        column-gap: 8px;
        row-gap: 8px;
        .type {
          border-radius: 4px;
          height: 20px;
          line-height: 12px;
          font-size: 10px;
          font-weight: 400;
          text-transform: capitalize;
          padding: 4px 8px;
          white-space: nowrap;
          color: #5a5f7d;
          // display: inline-block;
          display: flex;
          align-items: center;
          background-color: #ebf1ff;
        }
        .more-item {
          cursor: default;
        }
      }
      .type-row-empty {
        height: 20px;
      }
      .engagement-row {
        width: 100%;
        margin: 28px auto 16px;
        padding: 0 32px;
        color: #5a5f7d;
        .engagement {
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: space-between;
          .label {
            display: flex;
            vertical-align: middle;
            align-items: center;
            margin-bottom: 12px;
            .engagement-icon {
              padding-right: 8px;
              color: #9299b8;
            }
            .engagement-label {
              line-height: 16px;
              color: #5a5f7d;
              font-weight: 500;
              font-size: 12px;
            }
          }
          .engagement-value {
            line-height: 16px;
            color: #5a5f7d;
            font-weight: 400;
            font-size: 12px;
          }
          &:last-child {
            .label {
              margin-bottom: 0;
            }
          }
        }
      }
      .influencer-divide {
        max-width: 228px;
        padding: 0 32px;
        margin: 0 auto 20px;
        border: 1px solid #f1f2f6;
      }
      .sentiment-value {
        width: 100%;
        margin: 0 auto 20px;
        padding: 0 32px;
        .sentiment-list {
          display: flex;
          .sentiment-block {
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            justify-content: center;
            .sentiment-circle {
              padding: 5px;
              border-radius: 24px;
              height: 24px;
              width: 24px;
              overflow: hidden;
              text-align: center;
              margin-right: 4px;
              line-height: 15px;
              &.positive {
                background-color: rgba(32, 201, 151, 0.15);
                color: #20c997;
              }
              &.neutral {
                background-color: #fff5e2;
                color: #febc3c;
              }
              &.negative {
                background-color: rgba(255, 77, 79, 0.15);
                color: #ff4d4f;
              }
            }
            .value {
              font-size: 12px;
              color: #5a5f7d;
              &.zero {
                color: #adbcd2;
              }
            }
          }
        }
      }
      .total-list {
        padding: 20px 16px;
        background-color: #f5f8ff;
        border: 1px solid #f1f2f6;
        border-radius: 0px 0px 8px 8px;
        display: flex;
        justify-content: space-around;
        .total-block {
          text-align: center;
          .value-row {
            font-size: 14px;
            font-weight: 500;
            color: #272b41;
            margin-bottom: 4px;
            &.blue-text {
              color: #3371ff;
            }
          }
          .unit-row {
            font-size: 13px;
            color: #9299b8;
            text-transform: capitalize;
          }
        }
      }
      @media screen and (max-width: 690px) {
        width: 100%;
        max-width: 100%;
      }
    }
    .grid-card-min {
      min-width: fit-content;
      max-width: 260px;
    }
  }
}

.expand-row {
  padding: 10px 0;
  border-top: 1px solid #f1f2f6;
  td {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .action-list {
    font-size: 13px;
  }
}
.total-list {
  margin: 0;
  .total-block {
    text-align: center;
    .value-row {
      font-size: 13px;
      line-height: 19px;
      font-weight: 500;
      color: #272b41;
      margin-bottom: 4px;
    }
    .unit-row {
      font-size: 11px;
      line-height: 16px;
      color: #9299b8;
      text-transform: capitalize;
    }
  }
}
.blue-text {
  color: #3371ff;
}

.text-center {
  margin: 36px 0 0 0;
}

.source-tag {
  line-height: 13px;
  color: #434870;
  font-weight: 500;
  font-size: 10px;
}

@media only screen and (max-width: 580px) {
  .source-row {
    justify-content: unset;
  }
  .text-center {
    margin: 23px 0 0 0;
  }
  .item-table {
    .item-row {
      td {
        &.gold-text,
        &.silver-text,
        &.bronze-text {
          width: 24px;
          height: 24px;
          margin-left: unset;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.grid-card {
  border-radius: 8px !important;
  margin-bottom: 0;
  .ant-card-body {
    padding: 0 !important;
  }
}
</style>
<style scoped>
.ant-tooltip .ant-tooltip-inner .type-row {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
  text-align: left;
  justify-content: left;
  column-gap: 8px;
  row-gap: 8px;
}

.ant-tooltip .ant-tooltip-inner .type-row .type {
  border-radius: 4px;
  height: 20px;
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 4px 8px;
  white-space: nowrap;
  color: #5a5f7d;
  display: flex;
  align-items: center;
  background-color: #ebf1ff;
}
</style>
